module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="newsList5">\r\n    <ul>\r\n        <li><a href="#">\r\n            <div class="img wow animate__fadeInUp" data-wow-duration=".8s">\r\n              <!-- <img src="' +
((__t = (require('./image/news-img4.jpg'))) == null ? '' : __t) +
'" alt=""> -->\r\n              <b style="background-image: url(' +
((__t = (require('./image/news-img4.jpg'))) == null ? '' : __t) +
');"></b>\r\n            </div>\r\n            <div class="text wow animate__fadeInUp" data-wow-duration=".8s">\r\n              <div class="date font-color-black">2020.06.10</div>\r\n              <h1>我是党员我带头│于志强：五年躬耕乡村 擘画振兴图景擘画振兴图景</h1>\r\n              <p>近期，青岛西海岸新区人力资源和社会保障协会召开2019年度网络表彰大会。会上对西海岸新区人力资源和社会保障工作先进单位进行表彰</p>\r\n            </div>\r\n          </a></li>\r\n        <li><a href="#">\r\n            <div class="img wow animate__fadeInUp" data-wow-duration=".8s">\r\n              <!-- <img src="' +
((__t = (require('./image/news-img5.jpg'))) == null ? '' : __t) +
'" alt=""> -->\r\n              <b style="background-image: url(' +
((__t = (require('./image/news-img5.jpg'))) == null ? '' : __t) +
');"></b>\r\n            </div>\r\n            <div class="text wow animate__fadeInUp" data-wow-duration=".8s">\r\n              <div class="date">2020.06.10</div>\r\n              <h1>我是党员我带头│于志强：五年躬耕乡村 擘画振兴图景</h1>\r\n              <p>近期，青岛西海岸新区人力资源和社会保障协会召开2019年度网络表彰大会。会上对西海岸新区人力资源和社会保障工作先进单位进行表彰</p>\r\n            </div>\r\n          </a></li>\r\n        <li><a href="#">\r\n            <div class="img wow animate__fadeInUp" data-wow-duration=".8s">\r\n              <!-- <img src="' +
((__t = (require('./image/news-img4.jpg'))) == null ? '' : __t) +
'" alt=""> -->\r\n              <b style="background-image: url(' +
((__t = (require('./image/news-img4.jpg'))) == null ? '' : __t) +
');"></b>\r\n            </div>\r\n            <div class="text wow animate__fadeInUp" data-wow-duration=".8s">\r\n              <div class="date">2020.06.10</div>\r\n              <h1>我是党员我带头│于志强：五年躬耕乡村 擘画振兴图景</h1>\r\n              <p>近期，青岛西海岸新区人力资源和社会保障协会召开2019年度网络表彰大会。会上对西海岸新区人力资源和社会保障工作先进单位进行表彰</p>\r\n            </div>\r\n          </a></li>\r\n        <li><a href="#">\r\n            <div class="img wow animate__fadeInUp" data-wow-duration=".8s">\r\n              <!-- <img src="' +
((__t = (require('./image/news-img5.jpg'))) == null ? '' : __t) +
'" alt=""> -->\r\n              <b style="background-image: url(' +
((__t = (require('./image/news-img5.jpg'))) == null ? '' : __t) +
');"></b>\r\n            </div>\r\n            <div class="text wow animate__fadeInUp" data-wow-duration=".8s">\r\n              <div class="date">2020.06.10</div>\r\n              <h1>我是党员我带头│于志强：五年躬耕乡村 擘画振兴图景</h1>\r\n              <p>近期，青岛西海岸新区人力资源和社会保障协会召开2019年度网络表彰大会。会上对西海岸新区人力资源和社会保障工作先进单位进行表彰</p>\r\n            </div>\r\n          </a></li>\r\n    </ul>\r\n</div>\r\n\r\n<div class="pages">\r\n  <div class="page">\r\n    <a><i class="iconfont icon-zuojiantou"></i></a>\r\n    <a class="hover">1</a>\r\n    <a href="#">2</a>\r\n    <a href="#">3</a>\r\n    <a href="#"><i class="iconfont icon-youjiantou"></i></a>\r\n  </div>\r\n</div>';

}
return __p
}