import './index.scss'

//监听浏览器高度变化 电脑端menu
!(function (){
    window.onresize = function (){
        let docHeight = document.body.clientHeight
        let $cmenuChunkEl = $('.cmenu-link-chunk')

        if(docHeight < 768){
            $cmenuChunkEl.css({
              'width': '16.66%',
            })
        }else{
            $cmenuChunkEl.css({
              'width': '33.33%',
            })
        }
    }
    
})()

$('.cmenu-bottom .icon-search').on('click', function () {
  $('.cmenu-bottom .search,.cmenu-bottom .form-btn').addClass('active')
})
$('.cmenu-bottom .icon-guanbi').on('click', function () {
  $('.cmenu-bottom .search,.cmenu-bottom .form-btn').removeClass('active')
})


$('.cmenu-bottom .ewm').mouseenter(function () {
  $('.cmenu-bottom .ewm .wx-ewm').fadeIn()
}).mouseleave(function () {
  $('.cmenu-bottom .ewm .wx-ewm').fadeOut()
});

//移动端menu
$('.phone-menu .phone-menu-item i,.phone-menu .phone-menu-item a').on('click', function () {
  // $('.phone-menu .phone-menu-item ul').slideUp();
  // $('.phone-menu .phone-menu-item i').removeClass('active-icon');
  // $(this).toggleClass('active_icon').next().slideDown();
  $(this).parent().children('ul').slideToggle().parent().siblings().children('ul').slideUp();
  $(this).parent().toggleClass('active');
  $(this).parent().siblings().removeClass('active');
})