module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="newsList6">\r\n    <ul>\r\n        <li class=" wow animate__fadeInUp" data-wow-duration=".8s"><a href="#">\r\n            <h1>青岛市委“不忘初心、牢记使命”主题教育第三巡回指导组到城发投资集团开展调研督导</h1>\r\n            <div class="news7-cont">\r\n                <div class="img">\r\n                  <!-- <img src="' +
((__t = (require('./image/news-img7.jpg'))) == null ? '' : __t) +
'" alt=""> -->\r\n                  <b style="background-image: url(' +
((__t = (require('./image/news-img7.jpg'))) == null ? '' : __t) +
');"></b>\r\n                </div>\r\n                <div class="date">2020.06.10</div>\r\n                <p>\r\n                  各地纪检监察机关深入基层现场察访，及时发现问题、深入分析背后原因，对个性问题督促相关部门整改，对共性问题提出合理化建议，精准施治、力破顽疾，切实为基层减负，让干部有更多时间抓落实，让企业把更多精力放在生产上。\r\n                </p>\r\n            </div>\r\n        </a></li>\r\n        <li class=" wow animate__fadeInUp" data-wow-duration=".8s"><a href="#">\r\n            <h1>青岛市委“不忘初心、牢记使命”主题教育第三巡回指导组到城发投资集团开展调研督导</h1>\r\n            <div class="news7-cont">\r\n              <div class="img">\r\n                <!-- <img src="' +
((__t = (require('./image/news-img7.jpg'))) == null ? '' : __t) +
'" alt=""> -->\r\n                <b style="background-image: url(' +
((__t = (require('./image/news-img7.jpg'))) == null ? '' : __t) +
');"></b>\r\n              </div>\r\n              <div class="date">2020.06.10</div>\r\n              <p>\r\n                各地纪检监察机关深入基层现场察访，及时发现问题、深入分析背后原因，对个性问题督促相关部门整改，对共性问题提出合理化建议，精准施治、力破顽疾，切实为基层减负，让干部有更多时间抓落实，让企业把更多精力放在生产上。\r\n              </p>\r\n            </div>\r\n        </a></li>\r\n        <li class=" wow animate__fadeInUp" data-wow-duration=".8s"><a href="#">\r\n            <h1>青岛市委“不忘初心、牢记使命”主题教育第三巡回指导组到城发投资集团开展调研督导</h1>\r\n            <div class="news7-cont">\r\n              <div class="img">\r\n                <!-- <img src="' +
((__t = (require('./image/news-img7.jpg'))) == null ? '' : __t) +
'" alt=""> -->\r\n                <b style="background-image: url(' +
((__t = (require('./image/news-img7.jpg'))) == null ? '' : __t) +
');"></b>\r\n              </div>\r\n              <div class="date">2020.06.10</div>\r\n              <p>\r\n                各地纪检监察机关深入基层现场察访，及时发现问题、深入分析背后原因，对个性问题督促相关部门整改，对共性问题提出合理化建议，精准施治、力破顽疾，切实为基层减负，让干部有更多时间抓落实，让企业把更多精力放在生产上。\r\n              </p>\r\n            </div>\r\n        </a></li>\r\n        <li class=" wow animate__fadeInUp" data-wow-duration=".8s"><a href="#">\r\n            <h1>青岛市委“不忘初心、牢记使命”主题教育第三巡回指导组到城发投资集团开展调研督导</h1>\r\n            <div class="news7-cont">\r\n              <div class="img">\r\n                <!-- <img src="' +
((__t = (require('./image/news-img7.jpg'))) == null ? '' : __t) +
'" alt=""> -->\r\n                <b style="background-image: url(' +
((__t = (require('./image/news-img7.jpg'))) == null ? '' : __t) +
');"></b>\r\n              </div>\r\n              <div class="date">2020.06.10</div>\r\n              <p>\r\n                各地纪检监察机关深入基层现场察访，及时发现问题、深入分析背后原因，对个性问题督促相关部门整改，对共性问题提出合理化建议，精准施治、力破顽疾，切实为基层减负，让干部有更多时间抓落实，让企业把更多精力放在生产上。\r\n              </p>\r\n            </div>\r\n        </a></li>\r\n    </ul>\r\n</div>\r\n<div class="pages">\r\n  <div class="page">\r\n    <a><i class="iconfont icon-zuojiantou"></i></a>\r\n    <a class="hover">1</a>\r\n    <a href="#">2</a>\r\n    <a href="#">3</a>\r\n    <a href="#"><i class="iconfont icon-youjiantou"></i></a>\r\n  </div>\r\n</div>';

}
return __p
}