module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header class="cheader">\r\n  <div class="cheader-logo">\r\n    <a href="#">\r\n      <img class="logo1" src="' +
((__t = ( require('./image/header-logo2.png') )) == null ? '' : __t) +
'" alt="">\r\n    </a>\r\n  </div>\r\n  <div class="cheader-hamburger" data-open="cmenu">\r\n   <span class="sp1">菜单</span><span class="sp2">关闭</span>\r\n   <div class="comp-header-hamburger">\r\n     <div class="Hhamburger Hhamburger-1">\r\n       <div></div>\r\n     </div>\r\n   </div>\r\n  </div>\r\n</header>\r\n';

}
return __p
}