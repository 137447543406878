import './index.scss'

// 点击打开导航栏
let $openMenuElArr = $('[data-open="cmenu"]')
let $Hhamburger = $('.Hhamburger')
$openMenuElArr.click(function () {
  // if (this.classList.value.indexOf('sp_nav_se') >= 0) {
  if ($Hhamburger.hasClass('sp_nav_se')) {
    $Hhamburger.removeClass('active')
    $('.cheader').removeClass('menu-header')
    // $('.menu').removeClass('active')
    $('.menu').fadeOut()
    $('.cmenu-link-chunk').each((i, el) => {
      let $el = el
      $el.classList.remove('animate__animated', 'animate__fadeInUp')
    })
    $('.Hhamburger-1').removeClass('sp_nav_se');
  } else {
    $Hhamburger.addClass('active')
    $('.cheader').addClass('menu-header')
    // $('.menu').addClass('active')
    $('.menu').fadeIn()
    $('.cmenu-link-chunk').each((i, el) => {
      let $el = el
      $el.classList.add('animate__animated', 'animate__fadeInUp')
      $el.style.setProperty('--animate-duration', i * 0.15 + 0.5 + 's');
    })
    $('.Hhamburger-1').toggleClass('sp_nav_se');
  }
})


$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let viewH = $(window).height(); //可见高度
  let contentH = $(document).height(); //内容高度
  let nybnTabScrollH = $('.nbanner').height() - $('.nbanner .nybanenr-tabs').height() - $('.cheader').height();
  // console.log(scroH,nybnTabScrollH)

  if (scroH < 785) {
    //内页banner滚动视差效果
    $('.nybanner b').css('top', scroH / 2 +'px');
  }
  //业务领域-地产页  背景滚动
  $('.ywly-dc2 b').css('left', 200 - scroH / 3 + 'px');

  //右侧滚动到顶部
  if (scroH < 900) {
    $('.scrollTop-icon').css('opacity',0);
  }else{
    $('.scrollTop-icon').css('opacity', 1);
  }
  if (contentH - (scroH + viewH) <= 320) { //距离底部高度小于180px
    $('.scrollTop-icon').addClass('footScrollTop')
  }else{
    $('.scrollTop-icon').removeClass('footScrollTop')
  }

  if(scroH >= nybnTabScrollH){
    $('.nbanner .nybanenr-tabs').addClass('nytabFixed')
  }else{
    $('.nbanner .nybanenr-tabs').removeClass('nytabFixed')
  }

});
