import './index.scss'

!(function () {
})()


$("#footer-search").focus(function () {
   $(this).closest('.bt-search').addClass('active');
}).blur(function () {
  $(this).closest('.bt-search').removeClass('active');
});
// $("#footer-search")

$('.scrollTop-icon').on('click',function(){
  $('html,body').animate({scrollTop:0},500);
})

$('.footer-contact .bt-icon .wx').mouseenter(function () {
  $('.footer-contact .bt-icon .wx .wx-ewm').fadeIn()
}).mouseleave(function(){
  $('.footer-contact .bt-icon .wx .wx-ewm').fadeOut()
});